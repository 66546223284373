import { Component, OnInit, Input } from '@angular/core';
import { Package } from 'src/app/interface/package';
import * as PackageActions from 'src/app/actions/packages.action';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-package-cart',
  templateUrl: './package-cart.component.html',
  styleUrls: ['./package-cart.component.scss']
})
export class PackageCartComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('package') package: Package;
  // tslint:disable-next-line:no-input-rename
  @Input('index') index: number;
  constructor(
    private storePackage: Store<{ packageStore: { packages: Package[]} }>,
  ) { }

  ngOnInit() {
    // console.log(this.package);
  }

  onDeletePackage(i: number) {
    this.storePackage.dispatch(new PackageActions.DeletePackage(i));
  }

  getNormalPrice(packag: Package) {
    if (packag.promo_price) {
      return 'Harga Normal';
    } else {
      return 'Harga ';
    }
  }

  setStyleNormalPrice(packag: Package) {
    if (packag.promo_price === null) {
      return  {
        'font-weight': '400'
      };
    } else {
      return  {
        'text-decoration': 'line-through'
      };
    }

  }

}
