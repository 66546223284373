<div class="package" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxLayout="row" fxLayoutAlign="start start">
  <div class="image" fxFlex="40%" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100%" fxFlex.sm="100%">
      <!-- <img *ngIf="package.image_preview; else noImage" [defaultImage]="'assets/loading.gif'" [lazyLoad]="package.image_preview"  alt=""> -->
      <img *ngIf="package.image_preview; else noImage" [src]="package.image_preview"  alt="">
      <span class="delete" (click)="onDeletePackage(index)">
          Hapus    
        </span>
  </div>
  <div class="detail" fxFlex="60%" fxFlex.xs="100%" fxFlex.sm="100%" fxLayout="column" fxLayoutAlign="start start">
    <h3 class="name">{{ package?.name }}</h3>
    <div class="normal-price"><span [ngStyle]="setStyleNormalPrice(package)">{{ package?.price | rupiah }} </span></div>
    <div class="promo-price" *ngIf="package.promo_price"><span>{{ package?.promo_price | rupiah }} </span></div>
    <div class="quantity">Quantity: {{ package?.quantity }}</div>
    <div class="deskripsi">Dekripsi Paket</div>
    <div>
      <p [innerHTML]="package.published_desc | lowercase"></p>
    </div>
  </div>  
  
</div>

<ng-template #noImage>
  <div class="default">
    <img src="assets/no-image.png" alt="">
  </div>
</ng-template>