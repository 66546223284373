import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers/app.reducer';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  $appStore: Observable<any>;
  action: string;
  link: string;
  isCheckoutStatus: any;
  constructor(
    private router: Router,
    private store: Store<AppState>,

  ) { }

  ngOnInit() {
    this.$appStore   = this.store.select('appStore');
    this.$appStore.pipe(map(store => store.actionButton )).subscribe(action => this.action = action);
    this.$appStore.pipe(map(store => store.linkAction )).subscribe(link => {
      this.link = link;
    });
    this.$appStore.pipe(map(store => store.isCheckoutStatus )).subscribe(status => {
      this.isCheckoutStatus = status;
    });
  }

  onNext() {
    this.router.navigate([this.link]);
  }

  getColor() {
    if (this.isCheckoutStatus) {
      return  {
        'background-color': '#FAC107'
      };
    } else {
      return  {
        'background-color': '#cccccc'
      };
    }

  }

}
