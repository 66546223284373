import * as AppActions from './../actions/app.action';

export interface AppState {
    title: string;
    order: number;
    actionButton: string;
    linkAction: string;
    isCheckoutStatus: boolean;
    loading: boolean;

}


const initialState: AppState = {
    title: '',
    order: null,
    actionButton: null,
    linkAction: null,
    isCheckoutStatus: false,
    loading: false,

};

export function appReducer(
    state: AppState = initialState,
    action: AppActions.AppActions
) {
    switch (action.type) {
        case AppActions.SET_TITLE:
            return { ...state, title: action.payload };
        case AppActions.SET_ORDER:
            return { ...state, order: action.payload };
        case AppActions.SET_ACTION_BUTTON:
            return { ...state, actionButton: action.payload };
        case AppActions.SET_ACTION_LINK:
            return { ...state, linkAction: action.payload };
        case AppActions.SET_LOADING:
            return { ...state, loading: action.payload };
        case AppActions.SET_CHECKOUT_STATUS:
        return { ...state, isCheckoutStatus: action.payload };
        default:
            return state;
    }

}
