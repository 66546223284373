import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(private http: HttpClient) { }

  getBanners(): Observable<any> {
    return this.http.get(environment.apiUrl + '/banners/')
      .pipe(
        map(response => response),
        pluck('data')
      );
  }
}
