import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { BodyComponent } from './core/body/body.component';
import { AuthGuardService } from './auth/auth.guard.service';

const routes: Routes = [
  {
    path: '',
    component: BodyComponent,
    children: [
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: () => import('./core/core.module').then(m => m.CoreModule)
      },
      {
        path: 'products',
        loadChildren: () => import('./components/products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'test',
        loadChildren: () => import('./components/test-products/test-products.module').then(m => m.TestProductsModule)
      },
      {
        path: 'packages',
        loadChildren: () => import('./components/packages/packages.module').then(m => m.PackagesModule)
      },
      {
        path: 'detail-product',
        loadChildren: () => import('./components/detail-product/detail-product.module').then(m => m.DetailProductModule)
      },
      {
        path: 'detail-package',
        loadChildren: () => import('./components/detail-package/detail-package.module').then(m => m.DetailPackageModule)
      },
      {
        path: 'cart',
        loadChildren: () => import('./components/cart/cart.module').then(m => m.CartModule)
      },
      {
        path: 'checkout',
        loadChildren: () => import('./components/checkout/checkout.module').then(m => m.CheckoutModule)
      },
      {
        path: 'confirm',
        loadChildren: () => import('./components/confirm/confirm.module').then(m => m.ConfirmModule)
      },
    ],
    canActivate: [AuthGuardService]
  },
  {
    path: 'signin',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '***',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'pages/page-404',
    loadChildren: () => import('./components/not-found404/not-found404.module').then(m => m.NotFound404Module)
  },
  { path: '**', redirectTo: 'pages/page-404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
          preloadingStrategy: PreloadAllModules,
          useHash: true
          })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
