import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as AppActions from 'src/app/actions/app.action';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs/operators';
import { AppState } from '../reducers/app.reducer';

export class AuthInterceptor implements HttpInterceptor {

    constructor(
      private store: Store<{ appStore: AppState }>,
    ) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
          }
          const token = JSON.parse(<string>localStorage.getItem('token'));
          // console.log(token)
          Promise.resolve(null).then(() => this.store.dispatch(new AppActions.Setloading(true)));
          if (token) {
            request = request.clone({
              headers: request.headers.set('Authorization', 'Bearer ' + token)
            });
          }
          // console.log(request);
          return next.handle(request).pipe(
            finalize(() => this.store.dispatch(new AppActions.Setloading(false)))
            // loading menjadi tidak aktif setelah proses ambil data selesai
          );
    }
}
