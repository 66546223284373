<h2 *ngIf="packages?.length > 0 || products?.length > 0" mat-dialog-title>Keranjang Anda</h2>
<div class="content">
  <div class="container-package" *ngIf="(packages?.length > 0 || products?.length > 0); else noCart"  fxLayout="column" fxLayoutAlign="start center">
      <div *ngIf="packages?.length > 0">
        <app-package-cart *ngFor="let package of packages; let i = index" [package]="package" [index]="i"></app-package-cart>
      </div>
      <div *ngIf="products?.length > 0">
        <app-product-cart *ngFor="let product of products; let i = index" [product]="product" [index]="i"></app-product-cart>
      </div>
  </div>
</div>
<div class="container-button" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" *ngIf="(packages?.length > 0 || products?.length > 0)">
  <span class="shooping-more" (click)=goShopping()><button mat-flat-button color="accent">Lanjutkan Belanja</button></span>
  <span class="pay" (click)=goToCart()><button mat-flat-button color="accent">Bayar</button></span>

</div>

<ng-template #noCart>
  <div class="cart-empty" fxLayout="column" fxLayoutAlign="center center">
    <span><img fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" src="assets/empty-cart.png" alt=""></span>
    <span>Keranjang anda masih kosong </span>
  </div>
</ng-template>