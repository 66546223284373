import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private loadingSubject = new BehaviorSubject<boolean>(null);
  private loading$ = this.loadingSubject.asObservable();
  private userSubject = new BehaviorSubject<string>(null);
  private user$: Observable<string> = this.userSubject.asObservable();
  private errorSubject = new BehaviorSubject<string>(null);
  private error$: Observable<string> = this.errorSubject.asObservable();

  constructor() { }

  setLoading(status: boolean) {
    this.loadingSubject.next(status);
  }

  getLoading(): Observable<boolean> {
    return this.loading$;
  }

  getUser(): Observable<string> {
    const user = localStorage.getItem('user');
    if (user) {
      this.userSubject.next(JSON.parse(user));
    }
    return this.user$;
  }

  getError(): Observable<string> {
    return this.error$;
  }

  setError(message: string): void {
    this.errorSubject.next(message);
  }
}
