import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { Observable, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Package } from 'src/app/interface/package';
import { Router } from '@angular/router';
import { Product } from 'src/app/interface/product';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-my-cart',
  templateUrl: './my-cart.component.html',
  styleUrls: ['./my-cart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyCartComponent implements OnInit {
  @Inject(MAT_DIALOG_DATA) data: any;
  packageStore$: Observable<{ packages: Package[]; }>;
  productStore$: Observable<{ products: Product[]; }>;
  packages$: Observable<Package[]>;
  products$: Observable<Product[]>;
  packages: Package[];
  products: Product[];
  constructor(
    private storePackage: Store<{ packageStore: { packages: Package[]} }>,
    private dialogRef: MatDialogRef<MyCartComponent>,
    private router: Router,
    private storeProduct: Store<{ productStore: { products: Product[]} }>,

  ) { }

  ngOnInit() {
    this.packageStore$ = this.storePackage.select('packageStore');
    this.productStore$ = this.storeProduct.select('productStore');
    this.packages$ = this.packageStore$.pipe(map(store => store.packages));
    this.products$ = this.productStore$.pipe(map(store => store.products));
    combineLatest(this.packages$, this.products$)
      .pipe(map(([packages, products]) => [packages, products]))
      .subscribe(data => {
        this.packages = data[0];
        this.products = data[1];
      });
  }

  goShopping() {
    this.dialogRef.close('test');
    this.router.navigate(['/home']);
  }

  goToCart() {
    this.dialogRef.close('test');
    this.router.navigate(['/cart']);
  }
}
