
<!-- <span class="heading" fxLayout="row" fxLayoutAlign="center start">
  <h3 fxFlex="70%" fxFlex.xs="100%" fxFlex.sm="100%">{{ (product?.published_name || product?.name) | uppercase }}</h3>
</span> -->

<div class="image">
  <!-- <img (click)="onClickImage()" *ngIf="product.image_preview; else noImage" [defaultImage]="'assets/loading.gif'" [lazyLoad]="product.image_preview"  alt="">       -->
  <img (click)="onClickImage()" *ngIf="product.image_preview; else noImage" [src]="product.image_preview"  alt="">      
</div>

<div class="detail-product" fxLayout="row" fxLayoutAlign="space-between start" >
    <div class="name" fxFlex="50%" fxLayout="row" fxLayoutAlign="start start" fxFlex.xs="50%" fxFlex.sm="50%">
      <h3>{{ (product?.published_name || product?.name) | uppercase }}</h3>       
    </div>

    <div class="price" fxFlex="50%" fxLayout="column" fxLayoutAlign="start end" fxFlex.xs="50%" fxFlex.sm="50%">
        <h2 class="price-promo" *ngIf="product.promo_price">{{ product.promo_price | rupiah }}</h2>
        <h4 class="price-normal" [ngStyle]="isLineThrough()">{{ product.price | rupiah }}</h4>
  
      </div>
</div>
<a mat-flat-button class="buy-button"  color="accent" (click)="onAddToCart(product)">Beli  </a>

<ng-template #noImage>
  <div class="default">
      <img (click)="onClickImage()" src="assets/no-image.png" alt="">
  </div>
</ng-template>