import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rupiah'
})
export class RupiahPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      if (typeof value !== 'number') {
        value = parseFloat(value);
      }
      return (
        'Rp. ' +
        value.toLocaleString('id-ID', {
          minimumFractionDigits: 0
        })
      );
    }
    return 'Rp. 0';
  }
}
