
<app-carousel></app-carousel>
<div class="container-packages">
    <div class="heading-package" fxLayout="row" fxLayoutAlign="space-between start">

      <div class="title">
          <h1>PAKET PROMO</h1>  
      </div>
      <div class="all-packages" fxLayout="row" fxLayoutAlign="flex-start center">
          <h2 (click)="onClickAllPromo()">Lihat semua promo </h2>
      </div>
    </div>
    <div *ngIf="packages" fxLayout="column" fxLayoutAlign="space-between center" >
      <app-package *ngFor="let package of packages" [package]="package" fxFlex="49%" fxFlex.xs="100%" fxFlex.sm="100%"></app-package>
    </div>
    <!-- <a mat-flat-button class="all-packages-button"  color="accent" routerLink="/packages">Semua paket</a>     -->
</div>

<div class="container-products">
    <div class="heading-product" fxLayout="row" fxLayoutAlign="space-between start">
        <div class="title">
            <h2>PRODUK</h2>
        </div>
        <div class="all-product" fxLayout="row" fxLayoutAlign="flex-start center">
          <h2 (click)="onClickAllProduk()">Lihat semua produk  </h2>
        </div>
    </div>
    <div class="list-products" fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="space-between center" >
      <app-product *ngFor="let product of products" [product]="product" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%"></app-product>

    </div>
</div>

<!-- <div class="container-info">
    <h2>INFO</h2>
    <div class="list-info" fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="space-between start">
      <app-info fxFlex="32%" fxFlex.xs="100%" fxFlex.sm="100%"></app-info>
      <app-info fxFlex="32%" fxFlex.xs="100%" fxFlex.sm="100%"></app-info>
      <app-info fxFlex="32%" fxFlex.xs="100%" fxFlex.sm="100%"></app-info>

    </div>
</div>

<div class="container-testimoni">
    <h2>TESTIMONI</h2>
    <div class="list-testimoni" fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="space-between start" >
      <app-testimoni fxFlex="32%" fxFlex.xs="100%" fxFlex.sm="100%"></app-testimoni>
      <app-testimoni fxFlex="32%" fxFlex.xs="100%" fxFlex.sm="100%"></app-testimoni>
      <app-testimoni fxFlex="32%" fxFlex.xs="100%" fxFlex.sm="100%"></app-testimoni>

    </div>
</div> -->