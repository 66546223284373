import * as ProductListActions from 'src/app/actions/products.action';
import { Product } from '../interface/product';

const initialState = {
    products: localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : []
};

const SubtractQuantity = (id: number, products: Product[]) => {
    const selectedProduct = products.filter(product => product.id === id);
    const index = products.indexOf(selectedProduct[0]);
    const quantityNow = selectedProduct[0].quantity > 0 ?
                        selectedProduct[0].quantity - 1 :
                        selectedProduct[0].quantity;
    const newSelectedProduct =  { ...selectedProduct[0], quantity: quantityNow};
    products[index] = newSelectedProduct;
    localStorage.setItem('products', JSON.stringify(products));
    return products;

};

const AddQuantity = (id: number, products: Product[]) => {
    const selectedProduct = products.filter(product => product.id === id);
    const index = products.indexOf(selectedProduct[0]);
    const quantityNow = selectedProduct[0].quantity + 1;
    const newSelectedProduct =  { ...selectedProduct[0], quantity: quantityNow};
    products[index] = newSelectedProduct;
    localStorage.setItem('products', JSON.stringify(products));
    return products;

};


const ChangeCheck = (productObj: any, products: Product[]) => {
    const selectedproduct = products.filter(product => product.id === productObj.product_id);
    const index = products.indexOf(selectedproduct[0]);
    const newSelectedproduct =  { ...selectedproduct[0], isCheckout: productObj.isCheckout};
    products[index] = newSelectedproduct;
    localStorage.setItem('products', JSON.stringify(products));
    return products;

   };


const RemoveCheckOut = (products: Product[]) => {
    const selectedProduct = products.filter(product => product.isCheckout === false);
    const newSelectedProduct = [...selectedProduct];
    localStorage.setItem('products', JSON.stringify(newSelectedProduct));
    return newSelectedProduct;
};

const ChangeCheckAll = (checked: boolean, products: Product[]) => {
    const newObj = [...products];
    newObj.map(product => product.isCheckout = checked);
    localStorage.setItem('products', JSON.stringify(newObj));
    return newObj;
   };


export function producteListReducer(state = initialState, action: ProductListActions.ProductListActions) {
    switch (action.type) {
        case ProductListActions.ADD_PRODUCT:
            localStorage.setItem('products', JSON.stringify([...state.products, action.payload]));
            return {
                ...state,
                products: [...state.products, action.payload]
            };
        case ProductListActions.ADD_PRODUCTS:
            localStorage.setItem('products', JSON.stringify([...state.products, ...action.payload]));
            return {
                ...state,
                products: [...state.products, ...action.payload]
            };
        case ProductListActions.REMOVE_PRODUCT:
            return {
                ...state,
                products: []
            };
        case ProductListActions.DELETE_PRODUCT:
            const products = [...state.products];
            const newProducts = [...products];
            newProducts.splice(action.payload, 1);
            localStorage.setItem('products', JSON.stringify(newProducts));
            return {
                ...state,
                products: newProducts
            };
        case ProductListActions.ADD_QUANTITY_PRODUCT:
            const newProductsAfteraAdded = AddQuantity(action.payload, [...state.products]);
            return {
                ...state,
                products: newProductsAfteraAdded
            };
        case ProductListActions.SUBTRACT_QUANTITY_PRODUCT:
            const newProductsAfterSubtracted = SubtractQuantity(action.payload, [...state.products]);
            return {
                ...state,
                products: newProductsAfterSubtracted
            };
        case ProductListActions.CHECKOUT_PRODUCT:
        const newProductAfterCheck = ChangeCheck(action.payload, [...state.products]);
            return {
                ...state,
                products: newProductAfterCheck
            };
        case ProductListActions.CHECKOUT_ALL_PRODUCT:
        const newProductAfterCheckAll = ChangeCheckAll(action.payload, [...state.products]);
            return {
                ...state,
                products: newProductAfterCheckAll
            };
        case ProductListActions.REMOVE_CHECKOUT_PRODUCT:
        const newProductAfterCheckout = RemoveCheckOut([...state.products]);
            return {
                ...state,
                products: newProductAfterCheckout
            };

        default:
            return state;
    }
    return state;
}

