import { Action } from '@ngrx/store';
import { Package } from '../interface/package';



export const ADD_PACKAGE = 'ADD_PACKAGE';
export const DELETE_PACKAGE = 'DELETE_PACKAGE';
export const ADD_QUANTITY = 'ADD_QUANTITY';
export const SUBTRACT_QUANTITY = 'SUBTRACT_QUANTITY';
export const REMOVE_PACKAGE = 'REMOVE_PACKAGE';
export const CHECKOUT_PACKAGE = 'CHECKOUT_PACKAGE';
export const CHECKOUT_ALL_PACKAGE = 'CHECKOUT_ALL_PACKAGE';
export const REMOVE_CHECKOUT_PACKAGE = 'REMOVE_CHECKOUT_PACKAGE';




export class AddPackage implements Action {
    readonly type = ADD_PACKAGE;
    constructor(public payload: Package) {}
}

export class DeletePackage implements Action {
    readonly type = DELETE_PACKAGE;
    constructor(public payload: number) {}
}

export class AddQuantity implements Action {
    readonly type = ADD_QUANTITY;
    constructor(public payload: number) {}
}

export class SubtractQuantity implements Action {
    readonly type = SUBTRACT_QUANTITY;
    constructor(public payload: number) {}
}

export class RemovePackage implements Action {
    readonly type = REMOVE_PACKAGE;
    constructor() {}
}

export class CheckoutPackage implements Action {
    readonly type = CHECKOUT_PACKAGE;
    constructor(public payload: any) {}
}


export class CheckoutAllPackage implements Action {
    readonly type = CHECKOUT_ALL_PACKAGE;
    constructor(public payload: boolean) {}
}

export class RemoveCheckoutPackage implements Action {
    readonly type = REMOVE_CHECKOUT_PACKAGE;
    constructor() {}
}
export type PackageListActions = AddPackage
                                | DeletePackage
                                | AddQuantity
                                | SubtractQuantity
                                | RemovePackage
                                | CheckoutPackage
                                | RemoveCheckoutPackage
                                | CheckoutAllPackage;


