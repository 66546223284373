import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-side-nav-list',
  templateUrl: './side-nav-list.component.html',
  styleUrls: ['./side-nav-list.component.scss']
})
export class SideNavListComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.registerIcon('cek_order', 'assets/icon/cek_order.svg');
    this.registerIcon('confirm', 'assets/icon/konfirmasi_pembayaran.svg');
    this.registerIcon('product', 'assets/icon/produk.svg');
    this.registerIcon('paket', 'assets/icon/paket_promo.svg');
    this.registerIcon('logout', 'assets/icon/log_out.svg');
    this.registerIcon('deposit', 'assets/icon/deposit.svg');

  }

  registerIcon(icon: string, path: string) {
    this.matIconRegistry.addSvgIcon(
      icon,
      this.domSanitizer.bypassSecurityTrustResourceUrl(path)
    );
  }

  onClickDeposit() {
    // window.location.href = environment.depositLink;
    const token = this.authService.getToken();
    const link = environment.dashboardLink + token + '&path=deposit';
    window.location.href = link;
  }

  onLogout() {
    this.authService.removAuth();
    this.router.navigate(['/signin']);
  }

  onCheckOrder() {
    window.location.href = environment.cekOrderLink;
  }

  onConfirm() {
    window.location.href = environment.confirmPaymentLink;
  }

  onPackages() {
    this.router.navigate(['/packages']);
  }

  onClickDashhboard() {
    const token = this.authService.getToken();
    const link = environment.dashboardLink + token + '&path=dashboard';
    window.location.href = link;
  }

  onClickProfile() {
    const token = this.authService.getToken();
    const link = environment.dashboardLink + token + '&path=profile';
    window.location.href = link;
  }
}
