import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { StoreModule, Store } from '@ngrx/store';
import { appReducer } from './reducers/app.reducer';
import { packageListReducer } from './reducers/packages.reducer';
import { producteListReducer } from './reducers/products.reducer';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
import { CommonModule } from '@angular/common';
import { AuthGuardService } from './auth/auth.guard.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    CommonModule,
    HttpClientModule,
    StoreModule.forRoot({
      appStore: appReducer,
      packageStore: packageListReducer,
      productStore: producteListReducer
     })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [Store]
    },
    AuthGuardService,
    AuthGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
