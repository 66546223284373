import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map, shareReplay, tap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();

@Injectable()
export class AuthService {
  constructor(
    private httpClient: HttpClient
  ) { }


  signIn(userObj: { username: string, password: string}) {
    return this.httpClient.post<any>(environment.apiUrl + '/auth', userObj)
              .pipe(
                shareReplay(),
                tap(response => {
                  this.saveLocalStorage(response);
                }),
                map(response => response),
                catchError(this.errorHandler)
              );
  }

  getExpiration() {
    const expiration = localStorage.getItem('expire');
    const expiresAt = JSON.parse(expiration);
    return expiresAt;
  }

  isTokenExpired() {
    const token = localStorage.getItem('token');
    if (token) {
      const expire = helper.isTokenExpired(token);
      return expire;
    } else {
      return false;
    }
  }

  saveLocalStorageFromDashboard(token) {
    localStorage.setItem('token', JSON.stringify(token));
    const decoded = helper.decodeToken(token);
    localStorage.setItem('user', JSON.stringify(decoded.sub.full_name));
    // console.log(decoded)
  }

  getUser() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user;
  }

  getToken() {
    const token = JSON.parse(localStorage.getItem('token'));
    return token;
  }

  getExpireDate() {
    const token = localStorage.getItem('token');
    if (token) {
      const expire = helper.getTokenExpirationDate(token);
      return expire;
    } else {
      return true;
    }
  }

  removAuth() {
    localStorage.clear()
  }

  clearPackageProductlocalStorage() {
    localStorage.removeItem('packages');
    localStorage.removeItem('products');
  }

  errorHandler(response: any) {
    return throwError(response.error.message || 'Server Error');
  }

  saveLocalStorage(response: any) {
    localStorage.setItem('user', JSON.stringify({
      name: response.data.full_name,
      phone: response.data.phone,
      email: response.data.email,
      user_id: response.data.id
    }));
    localStorage.setItem('token', JSON.stringify(response.meta.token));

  }
}
