import { NgModule } from '@angular/core';
import { RupiahPipe } from './rupiah.pipe';

@NgModule({
  declarations: [RupiahPipe],
  imports: [],
  exports: [
    RupiahPipe
  ]
})
export class PipesModule { }
