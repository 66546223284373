<mat-nav-list class="nav" routerLink="/home">
    <a mat-list-item routerLink="/">
        <mat-icon class="icon" fxLayout="row" fxLayoutAlign="center center">
            home
        </mat-icon>
        <span>HOME</span>
    </a>
</mat-nav-list>
<mat-nav-list class="nav" (click)="onClickProfile()">
        <a mat-list-item>
            <mat-icon class="icon" fxLayout="row" fxLayoutAlign="center center">
                    account_circle
            </mat-icon>
            <span>Profile</span>
        </a>
    </mat-nav-list>

    <mat-nav-list class="nav" (click)="onClickDashhboard()">
            <a mat-list-item>
                <mat-icon class="icon" fxLayout="row" fxLayoutAlign="center center">
                        dashboard
                </mat-icon>
                <span>Dashboard</span>
            </a>
    </mat-nav-list>
    
<mat-nav-list class="nav" routerLink="/packages">
    <a  mat-list-item>
        <mat-icon class="icon" confirm svgIcon="paket" fxLayout="row" fxLayoutAlign="center center"></mat-icon>              
        <span>Paket Promo</span>
    </a>
</mat-nav-list>
<mat-nav-list class="nav" routerLink="/products">
    <a mat-list-item routerLink="/">
        <mat-icon class="icon" confirm svgIcon="product" fxLayout="row" fxLayoutAlign="center center"></mat-icon>        
        <span>Produk</span>
    </a>
</mat-nav-list>  

<mat-nav-list class="nav" (click)="onConfirm()">
    <a  mat-list-item>
        <mat-icon class="icon" confirm svgIcon="confirm" fxLayout="row" fxLayoutAlign="center center"></mat-icon>
        <span>Konfirmasi Pembayaran</span>
    </a>
</mat-nav-list>

<mat-nav-list class="nav" (click)="onClickDeposit()">
    <a mat-list-item>
        <mat-icon class="icon" fxLayout="row" fxLayoutAlign="center center">
            account_balance_wallet
        </mat-icon>
        <span>Deposit</span>
    </a>
</mat-nav-list>

<mat-nav-list class="nav" (click)="onCheckOrder()">
    <a mat-list-item>
        <mat-icon class="icon" svgIcon="cek_order" fxLayout="row" fxLayoutAlign="center center"></mat-icon>
        <span>Cek Order</span>
    </a>
</mat-nav-list>

<mat-nav-list class="nav" (click)="onLogout()">
    <a mat-list-item routerLink="/">
        <mat-icon class="icon" fxLayout="row" fxLayoutAlign="center center">
                exit_to_app
            </mat-icon>
        <span>Logout</span>
    </a>
</mat-nav-list>