import { Component, OnInit, AfterViewInit, AfterViewChecked } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { Observable, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers/app.reducer';
import { ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { map } from 'rxjs/operators';
import { Package } from 'src/app/interface/package';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Product } from 'src/app/interface/product';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from './../../../environments/environment';
import { MyCartComponent } from '../home/header/my-cart/my-cart.component';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit, AfterViewInit, AfterViewChecked {
  status = 'hide';
  error$: Observable<string>;
  $appStore: Observable<AppState>;
  $title: Observable<string>;
  $user: Observable<string>;
  totalCart: number = null;
  packageStore$: Observable<{ packages: Package[]; }>;
  productStore$: Observable<{ products: Product[]; }>;
  packages$: Observable<Package[]>;
  products$: Observable<Product[]>;
  $loading: Observable<any>;
  constructor(
    private appService: AppService,
    private store: Store<AppState>,
    private renderer: Renderer2,
    private router: Router,
    private storePackage: Store<any>,
    private storeProduct: Store<any>,
    private dialog: MatDialog,
    private authService: AuthService
  ) { }

  ngOnInit() {
    // console.log('load ngOnginit body');
    this.error$ = this.appService.getError();
    this.error$.subscribe(error => console.log(error));
    this.$appStore   = this.store.select('appStore');
    this.$title = this.$appStore.pipe(map(store => store.title ));
    this.$loading   = this.store.select('appStore').pipe(map(store => store.loading));
    this.$user = this.appService.getUser();
    this.getTotalcart();
  }

  getTotalcart() {
    this.packageStore$ = this.storePackage.select('packageStore');
    this.productStore$ = this.storeProduct.select('productStore');
    this.packages$ = this.packageStore$.pipe(map(store => store.packages));
    this.products$ = this.productStore$.pipe(map(store => store.products));

    combineLatest(this.packages$, this.products$)
      .pipe(map(([packages, products]) => [packages, products]))
      .subscribe(data => {
        const packageQuantity = this.getTotalPackageQuantity(data[0]);
        const productQuantity = this.getTotalProductQuantity(data[1]);
        this.totalCart = packageQuantity + productQuantity;
      });

  }

  ngAfterViewInit() {

  }

  ngAfterViewChecked() {}

  onConfirm() {
    window.location.href = environment.confirmPaymentLink;
  }

  getTotalProductQuantity(products: Product[]) {
    // console.log(products);
    let total = 0;
    for (let index = 0; index < products.length; index++) {
      const product = products[index];
      total += product.quantity;
    }
    return total;
  }

  getTotalPackageQuantity(packages: Package[]) {
    // console.log(packages);
    let total = 0;
    for (let index = 0; index < packages.length; index++) {
      const packag = packages[index];
      total += packag.quantity;
    }
    return total;
  }

  onLogout() {
    this.authService.removAuth();
    this.router.navigate(['/signin']);
  }

  showMyCart() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {};
    dialogConfig.width = '520px';
    const dialogRef = this.dialog.open(MyCartComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(val => {
      //console.log(val);
    });
  }

  onCheckOrder() {
    window.location.href = environment.cekOrderLink;
  }

}
