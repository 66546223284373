import {
  Component,
  OnInit,
  AfterViewInit,
  AfterViewChecked,
  OnDestroy,
} from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { Store } from '@ngrx/store';
import * as AppActions from 'src/app/actions/app.action';
import { PackagesService } from 'src/app/services/packages.service';
import { map } from 'rxjs/operators';
import { Package } from 'src/app/interface/package';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { Product } from 'src/app/interface/product';
import { ProductService } from 'src/app/services/product.service';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  packages: any;
  packages$: Observable<Package[]>;
  products$: Observable<Product[]>;
  products: any;
  subscriptionPackageProduct: Subscription;
  constructor(
    private appService: AppService,
    private store: Store<{ appStore: {} }>,
    private packageService: PackagesService,
    private productService: ProductService,
    private router: Router
  ) {
    this.store.dispatch(new AppActions.SetTitle(null));
  }

  ngOnInit() {}

  ngOnDestroy() {}

  ngAfterViewInit() {
    // data.slice(0, 5)
    this.packages$ = this.packageService.getPackagePublished();
    this.products$ = this.productService
      .getProductPublished()
      .pipe(map(data => data.slice(0, 5)));
    this.products$.subscribe(products => {
      const newProducts = products.filter(product => product.is_published);
      this.products = newProducts;
    });
    this.packages$.subscribe(packages => {
      const newPackages = packages.filter(
        packag => !packag.is_register && packag.is_published
      );
      this.packages = newPackages;
    });
  }

  onClickAllPromo() {
    this.router.navigate(['/packages']);
  }

  onClickAllProduk() {
    this.router.navigate(['/products']);
  }
}
