import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { CoreService } from '../../core.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent implements OnInit {
  imgags = [];
  @ViewChild('myCarousel', { static: true }) myCarousel: any;
  public carouselTileItems: Array<any> = [];
  public carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    speed: 250,
    point: {
      visible: true
    },
    load: 2,
    loop: true,
    velocity: 0,
    interval: {
      timing: 6000,
      initialDelay: 3000
    },
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };
  constructor(
    private coreService: CoreService
  ) {}

  ngOnInit() {
    this.coreService.getBanners()
      .subscribe(data => {
        this.getBanner(data);
      });
  }

  getBanner(data: any) {
    for (let index = 0; index < data.length; index++) {
      this.carouselTileItems.push(
        data[index].image
      );
    }
  }



}
