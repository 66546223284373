import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './home/header/header.component';
import { CarouselComponent } from './home/carousel/carousel.component';
import { HomeComponent } from './home/home.component';
import { MaterialModule } from '../shared/material.module';
import { CoreService } from './core.service';
import { BodyComponent } from './body/body.component';
import { Routes, RouterModule } from '@angular/router';
import { PackageComponent } from './home/package/package.component';
import { ProductComponent } from './home/product/product.component';
import { InfoComponent } from './home/info/info.component';
import { TestimoniComponent } from './home/testimoni/testimoni.component';
import { FooterComponent } from './home/footer/footer.component';
import { MyCartComponent } from './home/header/my-cart/my-cart.component';
import { PackageCartComponent } from './home/header/my-cart/package-cart/package-cart.component';
import { ProductCartComponent } from './home/header/my-cart/product-cart/product-cart.component';
import { PackagesService } from '../services/packages.service';
import { SharedModule } from '../shared/shared.module';
import { ProductService } from '../services/product.service';
import { AuthGuardService } from '../auth/auth.guard.service';
import { AuthService } from '../auth/auth.service';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NguCarouselModule } from '@ngu/carousel';
import { SideNavListComponent } from './side-nav-list/side-nav-list.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  }
];

@NgModule({
  // tslint:disable-next-line:max-line-length
  declarations: [HeaderComponent, CarouselComponent, HomeComponent, BodyComponent, PackageComponent, ProductComponent, InfoComponent, TestimoniComponent, FooterComponent, MyCartComponent, PackageCartComponent, ProductCartComponent, SideNavListComponent],
  imports: [
    CommonModule,
    MaterialModule,
    LazyLoadImageModule,
    NguCarouselModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    HomeComponent,
    CarouselComponent,
    HeaderComponent
  ],
  entryComponents: [
    MyCartComponent
  ],
  providers: [
    CoreService,
    PackagesService,
    ProductService,
    AuthGuardService,
    AuthService
  ]
})
export class CoreModule { }
