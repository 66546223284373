import { Component, OnInit, Input } from '@angular/core';
import { Product } from 'src/app/interface/product';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MyCartComponent } from 'src/app/core/home/header/my-cart/my-cart.component';
import { Store } from '@ngrx/store';
import * as ProductActions from 'src/app/actions/products.action';
import { Package } from 'src/app/interface/package';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('product') product: Product;
  $productStore: any;
  productsCart: Package[];
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private appService: AppService,
    private storeProduct: Store<{ productStore: { products: Product[]} }>,
  ) { }

  ngOnInit() {
    this.$productStore = this.storeProduct.select('productStore');
    this.$productStore.subscribe((store: any) => {
      this.productsCart = store.products;
    });
  }

  showMyCart() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {};
    dialogConfig.width = '520px';
    const dialogRef = this.dialog.open(MyCartComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(val => {
      //console.log(val);
    });
  }

  onClickImage() {
    this.router.navigate(['/detail-product/', this.product.id]);
  }

  isLineThrough() {
    if (this.product.promo_price) {
      return { 'text-decoration': 'line-through' };
    } else {
      return {
        'font-weight': '400',
        'font-size': '16px'
      };
    }
  }

  onAddToCart(product: Product) {
    // tslint:disable-next-line:no-shadowed-variable
    const result = this.productsCart.filter(data => data.id === product.id);
    if (result.length === 0) {
      this.storeProduct.dispatch( new ProductActions.AddProduct({
        id: product.id,
        name: product.name,
        quantity: 1,
        price: product.price,
        promo_price: product.promo_price,
        published_desc: product.published_desc,
        quantity_sent: 0,
        weight: product.weight,
        image_preview: product.image_preview,
        isCheckout: false

      }));
    } else {
      this.storeProduct.dispatch( new ProductActions.AddQuantity(product.id));
    }
    this.showMyCart();
  }

}
