<!-- <span class="heading" fxLayout="row" fxLayoutAlign="center start">
    <h3 fxFlex="70%" fxFlex.xs="100%" fxFlex.sm="100%">{{ (package?.published_name || package?.name) | uppercase }}</h3>
</span> -->

<div class="image">
  <!-- <img (click)="onClickImage()" *ngIf="package.image_preview; else noImage" [defaultImage]="'assets/loading.gif'" [lazyLoad]="package.image_preview" alt="">       -->
  <img (click)="onClickImage()" *ngIf="package.image_preview; else noImage" [src]="package.image_preview" alt="">      
</div>

<div class="detail-package" fxLayout="row" fxLayoutAlign="space-between start" >
    <div class="name" fxFlex="50%" fxLayout="row" fxLayoutAlign="start start" fxFlex.xs="50%" fxFlex.sm="50%">
        <h3>{{ (package?.published_name || package?.name) | uppercase }}</h3>
      </div>

    <div class="price" fxFlex="50%" fxLayout="column" fxLayoutAlign="start end" fxFlex.xs="50%" fxFlex.sm="50%">
        <h2 class="price-promo" *ngIf="package.promo_price">{{ package.promo_price | rupiah }}</h2>
        <h4 class="price-normal" [ngStyle]="isLineThrough()">{{ package.price | rupiah }}</h4>
  
      </div>

    <!-- <div class="detail" fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="50%">
        <h4>Details</h4>
        <p [innerHTML]="package.published_desc"></p>
     </div> -->

</div>

<a mat-flat-button class="buy-button"  color="accent" (click)="onAddToCart(package)">Beli</a>  


<ng-template #noImage>
  <div class="default">
    <img (click)="onClickImage()" src="assets/no-image.png" alt="">
  </div>
</ng-template>