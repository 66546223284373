import { Component, OnInit, Input } from '@angular/core';
import { Product } from 'src/app/interface/product';
import * as ProductActions from 'src/app/actions/products.action';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-product-cart',
  templateUrl: './product-cart.component.html',
  styleUrls: ['./product-cart.component.scss']
})
export class ProductCartComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('product') product: Product;
  // tslint:disable-next-line:no-input-rename
  @Input('index') index: number;
  constructor(
    private storeProduct: Store<{ productStore: { products: Product[]} }>,
  ) { }

  ngOnInit() {
    // console.log(this.product);
  }

  onDeleteProduct(i: number) {
    this.storeProduct.dispatch(new ProductActions.DeleteProduct(i));
  }

  getNormalPrice(product: Product) {
    if (product.promo_price) {
      return 'Harga Normal';
    } else {
      return 'Harga ';
    }
  }


  setStyleNormalPrice(product: Product) {
    if (product.promo_price === null) {
      return  {
        'font-weight': '400'
      };
    } else {
      return  {
        'text-decoration': 'line-through'
      };
    }

  }

}
