import { Action } from '@ngrx/store';
import { environment } from './../../environments/environment';
export const SET_TITLE = 'SET_TITLE';
export const SET_ORDER = 'SET_ORDER';
export const SET_ACTION_BUTTON = 'SET_ACTION_BUTTON';
export const SET_ACTION_LINK = 'SET_ACTION_LINK';
export const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA';
export const SET_CHECKOUT_STATUS = 'SET_CHECKOUT_STATUS';
export const SET_LOADING = 'SET_LOADING';

export class SetTitle implements Action {
    readonly type = SET_TITLE;
    constructor(public payload: string) {}
}

export class Setloading implements Action {
    readonly type = SET_LOADING;
    constructor(public payload: boolean) {
    }
}

export class SetOrder implements Action {
    readonly type = SET_ORDER;
    constructor(public payload: number) {
    }
}

export class SetActionLink implements Action {
    readonly type = SET_ACTION_LINK;
    constructor(public payload: string) {
    }
}

export class SetActionButton implements Action {
    readonly type = SET_ACTION_BUTTON;
    constructor(public payload: string) {
    }
}

export class SetCheckoutStatus implements Action {
    readonly type = SET_CHECKOUT_STATUS;
    constructor(public payload: boolean) {
    }
}




export type AppActions = SetTitle |  SetOrder | SetActionButton | SetActionLink | SetCheckoutStatus | Setloading;
