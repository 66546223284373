<div class="product" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" fxLayout="row" fxLayoutAlign="start start">
  <div class="image" fxLayoutGap="10px" fxFlex="40%" fxLayout="column" fxLayoutAlign="start center" fxFlex.xs="100%" fxFlex.sm="100%">
      <!-- <img *ngIf="product.image_preview; else noImage" [defaultImage]="'assets/loading.gif'" [lazyLoad]="product.image_preview"  alt=""> -->
      <img *ngIf="product.image_preview; else noImage" [src]="product.image_preview"  alt="">
      <span class="delete" (click)="onDeleteProduct(index)">
          Hapus    
        </span>
  </div>
  <div class="detail" fxFlex="60%" fxFlex.xs="100%" fxFlex.sm="100%" fxLayout="column" fxLayoutAlign="start start">
    <h3 class="name">{{ product?.name }}</h3>
    <span [ngStyle]="setStyleNormalPrice(product)">{{ product?.price | rupiah }} </span>
    <span *ngIf="product.promo_price">{{ product?.promo_price | rupiah }} </span>
    <span class="quantity">Quantity: {{ product?.quantity }}</span>
    <span class="deskripsi">Dekripsi Produk </span>
    <div>
      <p [innerHTML]="product.published_desc | lowercase"></p>
    </div>
  </div>  
  
</div>

<ng-template #noImage>
  <div class="default">
    <img src="assets/no-image.png" alt="">
  </div>
</ng-template>