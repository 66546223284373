import { Component, OnInit, Input } from '@angular/core';
import { Package } from 'src/app/interface/package';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import * as PackageActions from 'src/app/actions/packages.action';
import { MyCartComponent } from '../header/my-cart/my-cart.component';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('package') package: Package;
  packageStore$: Observable<{}>;
  packageCart: Package[];
  constructor(
    private router: Router,
    private appService: AppService,
    private storePackage: Store<{ packageStore: { packages: Package[]} }>,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.packageStore$ = this.storePackage.select('packageStore');
    this.packageStore$
    .subscribe((response: any) => {
      this.packageCart = response.packages;
    });
  }

  onClickImage() {
    this.router.navigate(['/detail-package/', this.package.id]);
  }

  getArrayProductFromPackage(packages: any) {
    const products = [];
    for (let index = 0; index < packages.product_packages.length; index++) {
      const product_package = packages.product_packages[index];

      products.push({
        id: product_package.product.id,
        name: product_package.product.name,
        qty: product_package.qty,
        weight: product_package.product.weight
      });
    }
    return products;
  }

  isLineThrough() {
    if (this.package.promo_price) {
      return { 'text-decoration': 'line-through' };
    } else {
      return {
        'font-weight': '400',
        'font-size': '16px'
      };
    }
  }

  onAddToCart(packag: any) {
    packag.products = this.getArrayProductFromPackage(packag);
    const result = this.packageCart.filter(data => data.id === packag.id);
    if (result.length === 0) {
      this.storePackage.dispatch( new PackageActions.AddPackage({
        id: packag.id,
        name: packag.name,
        quantity: 1,
        price: packag.price,
        promo_price: packag.promo_price,
        published_desc: packag.published_desc,
        products: packag.products,
        image_preview: packag.image_preview
      }));
    } else {
      this.storePackage.dispatch( new PackageActions.AddQuantity(packag.id));
    }
    this.showMyCart();
  }


  showMyCart() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {};
    dialogConfig.width = '520px';
    const dialogRef = this.dialog.open(MyCartComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(val => {
      //console.log(val);
    });
  }

}
