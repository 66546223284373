export const environment = {
  production: true,
  apiUrl: 'https://alpha.photostory.id:3000',
  appName: 'photostory-inspiralocal',
  confirmPaymentLink: 'https://photostory-reseller.firebaseapp.com/#/resi',
  cekOrderLink: 'https://staging.flow.photostory.id/#/cekorder',
  depositLink: 'https://photostory-reseller.firebaseapp.com/#/auth/sso?token=',
  dashboardLink: 'https://photostory-reseller.firebaseapp.com/#/auth/sso?token=',
  profileLink: 'https://photostory-reseller.firebaseapp.com/#/auth/sso?token=',
  dropboxPath: '/Logo',
  dropboxKey: 'yJ5_8RVoZAAAAAAAAAAAMWonKgWAoc25PinXnKsXPtG_U-yc4U_dOUmJlPXhuY9M',
  rajaOngkirKey: 'cb5107e448d1c4f1b73d57305e1cea18'
};
