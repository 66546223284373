import { Action } from '@ngrx/store';
import { Product } from '../interface/product';



export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const ADD_QUANTITY_PRODUCT = 'ADD_QUANTITY_PRODUCT';
export const SUBTRACT_QUANTITY_PRODUCT = 'SUBTRACT_QUANTITY_PRODUCT';
export const CHECKOUT_PRODUCT = 'CHECKOUT_PRODUCT';
export const CHECKOUT_ALL_PRODUCT = 'CHECKOUT_ALL_PRODUCT';
export const REMOVE_CHECKOUT_PRODUCT = 'REMOVE_CHECKOUT_PRODUCT';


export class AddProduct implements Action {
    readonly type = ADD_PRODUCT;
    constructor(public payload: Product) {}
}

export class AddProducts implements Action {
    readonly type = ADD_PRODUCTS;
    constructor(public payload: Product[]) {}
}

export class DeleteProduct implements Action {
    readonly type = DELETE_PRODUCT;
    constructor(public payload: number) {}
}

export class AddQuantity implements Action {
    readonly type = ADD_QUANTITY_PRODUCT;
    constructor(public payload: number) {}
}

export class SubtractQuantity implements Action {
    readonly type = SUBTRACT_QUANTITY_PRODUCT;
    constructor(public payload: number) {}
}

export class RemoveProduct implements Action {
    readonly type = REMOVE_PRODUCT;
    constructor() {}
}

export class CheckoutProduct implements Action {
    readonly type = CHECKOUT_PRODUCT;
    constructor(public payload: any) {}
}

export class CheckoutAllProduct implements Action {
    readonly type = CHECKOUT_ALL_PRODUCT;
    constructor(public payload: boolean) {}
}

export class RemoveCheckoutProduct implements Action {
    readonly type = REMOVE_CHECKOUT_PRODUCT;
    constructor() {}
}

export type ProductListActions = AddProduct
                                | DeleteProduct
                                | AddQuantity
                                | SubtractQuantity
                                | AddProducts
                                | RemoveProduct
                                | CheckoutProduct
                                | CheckoutAllProduct
                                | RemoveCheckoutProduct;


