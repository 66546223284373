import * as PackageListActions from 'src/app/actions/packages.action';
import { Package } from '../interface/package';

const initialState = {
    packages: localStorage.getItem('packages') ? JSON.parse(localStorage.getItem('packages')) : []
};

const SubtractQuantity = (id: number, packages: Package[]) => {
    const selectedPackage = packages.filter(packag => packag.id === id);
    const index = packages.indexOf(selectedPackage[0]);
    const quantityNow = selectedPackage[0].quantity > 1 ?
                        selectedPackage[0].quantity - 1 :
                        selectedPackage[0].quantity;
    const newSelectedPackage =  { ...selectedPackage[0], quantity: quantityNow};
    packages[index] = newSelectedPackage;
    localStorage.setItem('packages', JSON.stringify(packages));
    return packages;

};

const AddQuantity = (id: number, packages: Package[]) => {
    const selectedPackage = packages.filter(packag => packag.id === id);
    const index = packages.indexOf(selectedPackage[0]);
    const quantityNow = selectedPackage[0].quantity + 1;
    const newSelectedPackage =  { ...selectedPackage[0], quantity: quantityNow};
    packages[index] = newSelectedPackage;
    localStorage.setItem('packages', JSON.stringify(packages));
    return packages;

   };

const ChangeCheck = (packageObj: any, packages: Package[]) => {
    const selectedPackage = packages.filter(packag => packag.id === packageObj.package_id);
    const index = packages.indexOf(selectedPackage[0]);
    const newSelectedPackage =  { ...selectedPackage[0], isCheckout: packageObj.isCheckout};
    packages[index] = newSelectedPackage;
    localStorage.setItem('packages', JSON.stringify(packages));
    return packages;

   };

const ChangeCheckAll = (checked: boolean, packages: Package[]) => {
    const newObj = [...packages];
    newObj.map(packag => packag.isCheckout = checked);
    localStorage.setItem('packages', JSON.stringify(newObj));
    return newObj;
   };

const RemoveCheckOut = (packages: Package[]) => {
    const selectedPackage = packages.filter(packag => packag.isCheckout === false);
    const newSelectedPackage = [...selectedPackage];
    localStorage.setItem('packages', JSON.stringify(newSelectedPackage));
    return newSelectedPackage;
   };

export function packageListReducer(state = initialState, action: PackageListActions.PackageListActions) {
    switch (action.type) {
        case PackageListActions.ADD_PACKAGE:
        localStorage.setItem('packages', JSON.stringify([...state.packages, action.payload]));
            return {
                ...state,
                packages: [...state.packages, action.payload]
            };
        case PackageListActions.DELETE_PACKAGE:
            const packages = [...state.packages];
            const newPackage = [...packages];
            newPackage.splice(action.payload, 1);
            localStorage.setItem('packages', JSON.stringify(newPackage));
            return {
                ...state,
                packages: newPackage
            };
        case PackageListActions.ADD_QUANTITY:
            const newPackagesAfteraAdded = AddQuantity(action.payload, [...state.packages]);
                return {
                    ...state,
                    packages: newPackagesAfteraAdded
                };
        case PackageListActions.SUBTRACT_QUANTITY:
            const newPackagesAfterSubtracted = SubtractQuantity(action.payload, [...state.packages]);
                return {
                    ...state,
                    packages: newPackagesAfterSubtracted
                };
        case PackageListActions.REMOVE_PACKAGE:
            localStorage.setItem('packages', JSON.stringify([]));
                return {
                    ...state,
                    packages: []
                };
        case PackageListActions.CHECKOUT_PACKAGE:
        const newPackagesAfterCheck = ChangeCheck(action.payload, [...state.packages]);
            return {
                ...state,
                packages: newPackagesAfterCheck
            };
        case PackageListActions.CHECKOUT_ALL_PACKAGE:
        const newPackagesAfterCheckAll = ChangeCheckAll(action.payload, [...state.packages]);
            return {
                ...state,
                packages: newPackagesAfterCheckAll
            };
        case PackageListActions.REMOVE_CHECKOUT_PACKAGE:
        const newPackagAfterCheckout = RemoveCheckOut([...state.packages]);
            return {
                ...state,
                packages: newPackagAfterCheckout
            };
        default:
            return state;
    }
    return state;
}

