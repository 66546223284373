<!-- <app-header></app-header> -->


<mat-sidenav-container fullscreen>
    <mat-sidenav #start class="sidenav">
        <app-side-nav-list></app-side-nav-list>      
    </mat-sidenav>
    <div class="container-heading" fxLayout="row" fxLayoutAlign="center start">
        <div class="heading"  fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
          <mat-toolbar fxLayout="row" fxLayoutAlign="center start" class="real-toolbar" color="primary">
              <div class="heading-title"  fxFlex="40%" fxFlex.xs="100%" fxFlex.sm="100%">
                  <span class="logo">
                      <button class="click" mat-icon-button (click)="start.open()">
                          <mat-icon>menu</mat-icon>
                      </button>
                  </span>
                  <span class="title" >
                      <span *ngIf="$title | async">
                        {{ $title | async }}
                      </span>
                      <img *ngIf="!($title | async)" src="assets/logo.png" alt="">
                  </span>
                  <span class="cart">
                      <button class="click" mat-icon-button (click)="showMyCart()">
                          <mat-icon [matBadge]="totalCart" matBadgeColor="accent">shopping_cart</mat-icon>
                      </button>
                      
                  </span>
                </div>
          </mat-toolbar>
        </div>

    </div>
    <div class="spinner-container" *ngIf="$loading | async">
        <mat-spinner></mat-spinner>
    </div>
    <div fxLayout="row" fxLayoutAlign="center start">
        <div class="body-container" fxFlex="40%" fxFlex.xs="100%" fxFlex.sm="70%" fxLayout="column" fxLayoutAlign="center start">
            <router-outlet></router-outlet>
            <span class="error-message" *ngIf="error$ | async">{{ error$ | async }}</span>
        </div>
    </div>
    <app-footer></app-footer>

</mat-sidenav-container>





