<ngu-carousel class="carousel" #myCarousel [inputs]="carouselTile" [dataSource]="carouselTileItems">
    <!-- <ngu-tile *nguCarouselDef="let item; let i = index">
      <div class="tile" [style.background]="'url(' + item + ')'" [style.width]="'100%'" style="min-height: 200px">
      </div>
    </ngu-tile> -->
    <ngu-item NguCarouselItem *nguCarouselDef="let item; let i = index">
      <img
      [src]="item"
      style="width: 100%"
      [alt]="item"
      />
    </ngu-item>
    <ul class="myPoint" NguCarouselPoint>
      <li *ngFor="let i of myCarousel.pointNumbers; let i = index" [class.active]="i==myCarousel.activePoint" (click)="myCarousel.moveTo(i)"
        [style.background]="'url(' + carouselTileItems[i] + ')'"></li>
    </ul>
  </ngu-carousel>
  
  